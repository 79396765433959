// simple regular expression to validate an email address
const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
// Hexadecimal, 16 characters for token validation
const tokenRegex = /^[a-f0-9]{16}$/i;

export function isValidEmail(email) {
    return emailRegex.test(email);
}

export function isValidToken(token){
    return tokenRegex.test(token);

}