import React from 'react';
import { Box, Typography, CircularProgress } from '@mui/material';
import { red, orange, blue, green } from '@mui/material/colors';

const LatencyTest = ({ latency }) => {
    const [status, setStatus] = React.useState({ text: "", color: "" });

    React.useEffect(() => {
        if (latency < 50) {
            setStatus({ text: "Excellent", color: green[500] });
        } else if (latency >= 50 && latency < 100) {
            setStatus({ text: "Great", color: blue[200] });
        } else if (latency >= 100 && latency < 200) {
            setStatus({ text: "Good", color: blue[500] });
        } else if (latency >= 200 && latency <= 300) {
            setStatus({ text: "OK", color: orange[500] });
        } else {
            setStatus({ text: "Bad", color: red[500] });
        }
    }, [latency]);

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                border: "1px solid",
                borderColor: "divider",
                borderRadius: 1,
                p: 1,
                width: "100%",
                backgroundColor: "background.paper",
            }}
        >
            <Typography variant="subtitle2" color="text.secondary">
                Connection Latency
            </Typography>
            {latency ? (
                <Typography variant="h5" sx={{ color: status.color }}>
                    {status.text}
                </Typography>
            ) : (
                <CircularProgress />
            )}
            <Typography variant="h6">{latency || "Testing..."} ms</Typography>
        </Box>
    );
};

export default LatencyTest;
