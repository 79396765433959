import React, { useState } from 'react';
import { Document, Page } from 'react-pdf';
import { pdfjs } from 'react-pdf';
import { Grid, Button, Dialog, DialogTitle, DialogContent, Typography } from '@mui/material';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

export default function ReconsentForm({ consent, setConsent }) {
    const [open, setOpen] = useState(false);
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [showDownloadButton, setShowDownloadButton] = useState(false);
    const [fileUrl, setFileUrl] = useState(null);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
        setShowDownloadButton(true);
        const fileUrl = process.env.PUBLIC_URL + '/files/ConsentForm_raffle.pdf';
        setFileUrl(fileUrl);
    }

    const handleDownload = () => {
        if (fileUrl) {
            const link = document.createElement('a');
            link.href = fileUrl;
            link.setAttribute('download', 'ConsentForm_raffle.pdf');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    };

    const goToPrevPage = () => setPageNumber(pageNumber - 1 <= 1 ? 1 : pageNumber - 1);
    const goToNextPage = () => setPageNumber(pageNumber + 1 >= numPages ? numPages : pageNumber + 1);

    return (
        <Grid container direction="column" spacing={2}>
            <Grid item>
                <Button variant="outlined" color="primary" onClick={handleOpen}>
                    New consent form
                </Button>
                <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
                    <DialogTitle>Consent Form</DialogTitle>
                    <DialogContent sx={{ padding: { xs: '16px', sm: '24px' } }}>
                        <nav style={{ textAlign: 'center' }}>
                            <Button
                                color="primary"
                                variant="outlined"
                                onClick={goToPrevPage}
                                sx={{ m: 1 }}
                            >
                                Prev
                            </Button>
                            <Button
                                color="primary"
                                variant="outlined"
                                onClick={goToNextPage}
                                sx={{ m: 1 }}
                            >
                                Next
                            </Button>
                            <Button
                                disabled={pageNumber !== numPages}
                                onClick={() => { handleClose(); setConsent(true); }}
                                variant="contained"
                                color="success"
                                sx={{ m: 1 }}
                            >
                                Accept
                            </Button>
                            {showDownloadButton && (
                                <Button
                                    onClick={handleDownload}
                                    variant="outlined"
                                    color="primary"
                                    sx={{ m: 1 }}
                                >
                                    Download
                                </Button>
                            )}
                            <Typography variant="body2" sx={{ mt: 2 }}>
                                Page {pageNumber} of {numPages}
                            </Typography>
                        </nav>
                        <Document
                            file={process.env.PUBLIC_URL + '/files/ConsentForm_raffle.pdf'}
                            onLoadSuccess={onDocumentLoadSuccess}
                        >
                            <Page
                                pageNumber={pageNumber}
                                renderTextLayer={false}
                                renderAnnotationLayer={false}
                                customTextRenderer={false}
                                scale={1.4}
                            />
                        </Document>
                    </DialogContent>
                </Dialog>
            </Grid>
        </Grid>
    );
}
