import React, { useState, useRef, useEffect } from 'react';
import { Document, Page } from 'react-pdf';
import { pdfjs } from 'react-pdf';
import { Grid, Button, Dialog, DialogTitle, DialogContent, DialogActions, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

export default function ConsentForm({ consent, setConsent }) {
    const [open, setOpen] = useState(false);
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [showDownloadButton, setShowDownloadButton] = useState(false);
    const [fileUrl, setFileUrl] = useState(null);
    const [pageWidth, setPageWidth] = useState(null);
    const [pdfAspectRatio, setPdfAspectRatio] = useState(1); // Default aspect ratio

    const containerRef = useRef(null);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
        setShowDownloadButton(true);
        const fileUrl = process.env.PUBLIC_URL + '/files/ConsentForm_raffle.pdf';
        setFileUrl(fileUrl);
    };

    const onPageLoadSuccess = (page) => {
        const width = page.originalWidth;
        const height = page.originalHeight;
        setPdfAspectRatio(width / height); // Set the exact aspect ratio of the PDF
    };

    const handleDownload = () => {
        if (fileUrl) {
            const link = document.createElement('a');
            link.href = fileUrl;
            link.setAttribute('download', 'ConsentForm_raffle.pdf');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    };

    const goToPrevPage = () => setPageNumber(Math.max(pageNumber - 1, 1));
    const goToNextPage = () => setPageNumber(Math.min(pageNumber + 1, numPages));

    useEffect(() => {
        const updatePageWidth = () => {
            if (containerRef.current) {
                const containerWidth = containerRef.current.clientWidth;
                const containerHeight = containerRef.current.clientHeight;

                const widthBasedOnHeight = containerHeight * pdfAspectRatio;
                setPageWidth(Math.min(containerWidth, widthBasedOnHeight));
            }
        };

        if (open) {
            updatePageWidth();
            window.addEventListener('resize', updatePageWidth);
        }

        return () => window.removeEventListener('resize', updatePageWidth);
    }, [open, pdfAspectRatio]);

    return (
        <Grid container direction="column" spacing={2}>
            <Grid item>
                <Button variant="outlined" color="primary" onClick={handleOpen}>
                    Consent form
                </Button>
                <Dialog
                    open={open}
                    onClose={handleClose}
                    maxWidth="lg"
                    fullWidth
                    fullScreen={isMobile}
                    sx={{ padding: 0 }}
                >
                    <DialogTitle sx={{ textAlign: 'center', padding: '8px' }}>Consent Form</DialogTitle>
                    <DialogContent
                        ref={containerRef}
                        sx={{
                            padding: 0,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            overflowY: 'auto',
                            height: 'calc(100vh - 128px)', // Adjust to keep room for buttons
                        }}
                    >
                        <Document
                            file={process.env.PUBLIC_URL + '/files/ConsentForm_raffle.pdf'}
                            onLoadSuccess={onDocumentLoadSuccess}
                        >
                            <Page
                                pageNumber={pageNumber}
                                renderTextLayer={false}
                                renderAnnotationLayer={false}
                                width={pageWidth}
                                onLoadSuccess={onPageLoadSuccess}
                            />
                        </Document>
                    </DialogContent>
                    <DialogActions sx={{ justifyContent: 'center', flexWrap: 'wrap', padding: '16px' }}>
                        <Button
                            color="primary"
                            variant="outlined"
                            onClick={goToPrevPage}
                            sx={{ m: 1 }}
                        >
                            Prev
                        </Button>
                        <Button
                            color="primary"
                            variant="outlined"
                            onClick={goToNextPage}
                            sx={{ m: 1 }}
                        >
                            Next
                        </Button>
                        <Button
                            disabled={pageNumber !== numPages}
                            onClick={() => {
                                handleClose();
                                setConsent(true);
                            }}
                            variant="contained"
                            color="success"
                            sx={{ m: 1 }}
                        >
                            Accept
                        </Button>
                        {showDownloadButton && (
                            <Button
                                onClick={handleDownload}
                                variant="outlined"
                                color="primary"
                                sx={{ m: 1 }}
                            >
                                Download
                            </Button>
                        )}
                        <Typography variant="body2" sx={{ mt: 2 }}>
                            Page {pageNumber} of {numPages}
                        </Typography>
                    </DialogActions>
                </Dialog>
            </Grid>
        </Grid>
    );
}
