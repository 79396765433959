import React from 'react';
import PacmanLoader from "react-spinners/PacmanLoader";

export default function LoadingScreen() {
    return (
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%', padding: 10 }}>
            <PacmanLoader color={"#36d7b7"} loading={true} size={30} />
        </div>
    );
}
