import React, { useState } from 'react';
import { Modal, TextField, Typography, Box, CircularProgress } from '@mui/material';
import CustomButton from '../components/CustomButton'; 
import { isValidEmail, isValidToken } from '../libs/validate';
import deployment_config from '../deployment_config.json'

function UnsubscribeForm({ open, onClose }) {
  const [email, setEmail] = useState('');
  const [token, setToken] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [tokenError, setTokenError] = useState(false);
  const [tokenEmailSent, setTokenEmailSent] = useState(false);
  const [isUnsubscribed, setIsUnsubscribed] = useState(false);
  const [submissionAttempted, setSubmissionAttempted] = useState(false);
  const [isLoadingEmailToken, setIsLoadingEmailToken] = useState(false);
  const [isLoadingUnsubscribe, setIsLoadingUnsubscribe] = useState(false);
  
  const handleEmailChange = (event) => {
    const emailValue = event.target.value;
    setEmail(emailValue);
    setEmailError(!isValidEmail(emailValue));
  };

  const handleTokenChange = (event) => {
    const tokenValue = event.target.value;
    setToken(tokenValue);
    setTokenError(!isValidToken(tokenValue));
  };

  const handleTokenRequest = async () => {
    if (!emailError && email) {
      setTokenEmailSent(false);
      setIsLoadingEmailToken(true);
  
      try {
        await fetch(deployment_config.apiBaseUrl + '/send-token-email', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                email
            })
        });
        setTokenEmailSent(true); 
      } catch (err) {
          setTokenEmailSent(false);
      } finally {
        setIsLoadingEmailToken(false);
      }
    }
  };

  const handleUnsubscribe = async (event) => {
    event.preventDefault();
    if (!emailError && email && !tokenError && token) {
      setIsLoadingUnsubscribe(true);
      setSubmissionAttempted(false);
      try {
        const res = await fetch(deployment_config.apiBaseUrl + '/unsubscribe-from-distribution', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                email,
                token,
            })
        });
        setIsUnsubscribed(res.ok);
      } catch (err) {
          setIsUnsubscribed(false);
      } finally {
        setIsLoadingUnsubscribe(false);
        setSubmissionAttempted(true); 
      }
    }
  };

  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    width: '100%',
    maxWidth: 400,
  };

  const instructionTextStyle = {
    fontSize: '0.875rem', 
    color: 'grey.600', 
    marginBottom: '16px' 
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="unsubscribe-modal-title"
      aria-describedby="unsubscribe-modal-description"
    >
      <Box sx={modalStyle}>
        <Typography variant="h6" component="h2" sx={{ mb: 3 }}>
          Unsubscribe
        </Typography>
        <Typography sx={instructionTextStyle}>
          Enter your email below and click "Request Token". If we find an account associated with your email, we will send you a token.
        </Typography>
        <TextField
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email Address"
          name="email"
          autoComplete="email"
          autoFocus
          value={email}
          onChange={handleEmailChange}
          error={emailError}
          helperText={emailError ? "Invalid email address." : ""}
          sx={{ mb: 2 }}
        />
        <Box sx={{ mb: 2 }}>
          <CustomButton
            isDisabled={!email || emailError}
            content={isLoadingEmailToken ? <CircularProgress size={24} /> : "Request Token"}
            color="primary"
            onClick={handleTokenRequest}
          />
        </Box>
        {tokenEmailSent && (
          <Typography color="secondary" sx={{ my: 2 }}>Email sent.</Typography>
        )}
        <Typography sx={instructionTextStyle}>
          To unsubscribe from the mailing list, enter the token you received below.
        </Typography>
        <TextField
          margin="normal"
          required
          fullWidth
          id="token"
          label="Token"
          name="token"
          value={token}
          onChange={handleTokenChange}
          error={tokenError}
          helperText={tokenError ? "Invalid token." : ""}
          sx={{ mb: 2 }}
        />
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <CustomButton
            isDisabled={!email || emailError || !token || tokenError}
            content={isLoadingUnsubscribe ? <CircularProgress size={24} /> : "Unsubscribe"}
            color="primary"
            onClick={handleUnsubscribe}
          />
        </Box>
        {submissionAttempted && isUnsubscribed && (
          <Typography color="secondary">
            Successfully unsubscribed {email}.
          </Typography>
        )}
        {submissionAttempted && !isUnsubscribed && (
          <Typography color="error">
            Failed to unsubscribe {email}. Please verify your token is correct and try again.
          </Typography>
        )}
      </Box>
    </Modal>
  );
}

export default UnsubscribeForm;