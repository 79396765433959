import React from 'react';
import { Box, Modal, Typography, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

export default function FAQ() {
    const [open, setOpen] = React.useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <span>
            <Typography
                component="span"
                variant="body1"
                onClick={handleOpen}
                sx={{ cursor: 'pointer', color: 'text.secondary', '&:hover': { textDecoration: 'underline' } }}
            >
                FAQ
            </Typography>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '70%',
                        height: '80%',
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                        overflow: 'auto',
                        padding: '50px',
                    }}
                >
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{ position: 'absolute', right: 8, top: 8 }}
                    >
                        <CloseIcon />
                    </IconButton>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Frequently Asked Questions
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        <b>Q: What happens if I accidentally close my PLAICraft browser tab?  Can I get back in?</b><br />
                        A: You will be able to rejoin the game where you left off, however, you will have to go through the process of getting a login token again unfortunately.<br /><br />

                        <b>Q: I can't move using WASD and look around at the same time. I'm using a trackpad.</b><br />
                        A: We've had problems playing with trackpad before. Try using an external mouse to see if it resolves your issue.<br /><br />

                        <b>Q: I can't move the mouse, seems like I'm always looking down.</b><br />
                        A: By default the VM use absolute mouse tracking, you need to click on the middle of the screen once to get into the fullscreen and then the mouse should work properly.<br /><br />

                        <b>Q: How to report other problems I have?</b><br />
                        A: Join our discord by clicking on the discord icon on the main page.<br /><br />

                        <b>Q: I want my data not to be used in the study. How do I get it deleted?</b><br />
                        A: Please join our discord first, you can find the invite link on the main page discord icon, then provide us the token used to initiate the game session you would like to have deleted.<br /><br />

                        <b>Q: I experienced abusive interactions during my PLAICraft experience, where do I report it?</b><br />
                        A: Please join our discord first, you can find the invite link on the main page discord icon, then provide us the token used to initiate the game session.  We will make best effort attempts to identify the perpetrators and ban them from contributing.<br /><br />

                        <b>Q: What exactly do you record and how?</b><br />
                        A: We record what you see in the full-screen PLAICraft browser window, the keystrokes and mouse actions you make only in the PLAICraft browser window, what you say via your microphone, and in-game audio. The software we use to accomplish these tasks is installed on our servers in the cloud; there is zero software installed on your computer. <br /><br />

                        <b>Q: I want to play with my friends?  How do I do this?</b><br />
                        A: As part of our research, we explicitly want to encourage people to play and interact with people whom they do not already know. This is to ensure our AI model is as diverse as possible. However, you can try to start a session at the same time with your friends for a higher chance of playing together, but we cannot guarantee you will all be spawned near each other.<br /><br />

                        <b>Q: Do I have to talk?</b><br />
                        A: Talking is not always required, but audio data is very important to us. If we think you've played for too long without speaking a word or iteracting with anyone, you could be banned from further access.<br /><br />
                    </Typography>
                </Box>
            </Modal>
        </span>
    );
}
