import * as React from 'react';
import CustomButton from './components/CustomButton';
import MicIcon from '@mui/icons-material/Mic';
import MicOffIcon from '@mui/icons-material/MicOff';
import { Grid, Typography, Button } from '@mui/material';

// import InstructionPanel from './components/InstructionPanel';
import SpeedTest from './components/SpeedTest';
import Fade from '@mui/material/Fade';
import LoadingScreen from './components/LoadingScreen';
import BandwidthTest from './components/BandwidthTest';
import deployment_config from './deployment_config.json';

export default function PromptForm({ promptId, email, setInstanceId, token, setToken, isMicEnabled, setIsMicEnabled, completedCaptcha, setCompletedCaptcha, setIsSubmitted, region, setRegion }) {
    const [latency, setLatency] = React.useState(0);
    const [isLoading, setLoading] = React.useState(false);
    const [bandwidth, setBandwidth] = React.useState(0);
    // const [suffix, setSuffix] = React.useState('');
    const apiBaseUrl = deployment_config.apiBaseUrl;

    const clear = () => {
        setToken('');
        setIsMicEnabled(false);
        setCompletedCaptcha(false);
        setIsSubmitted(false);
    }

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault(); // Prevent the default form submit action
            if (token && isMicEnabled) {
                finalSubmit();
            }
        }
    }

    const enableMic = () => {
        // Enable microphone by asking for permissions to use microphone
        navigator.mediaDevices.getUserMedia({ audio: true })
            .then(stream => {
                setIsMicEnabled(true);
            }
            )
            .catch(err => {
                alert("We need to use your microphone to play our game. Please enable it and retry.");
            }
            );
    }

    React.useEffect(() => {
        // const queryParams = new URLSearchParams(window.location.search);
        // const urlToken = queryParams.get('token');
        // if (urlToken) {
        //     setToken(urlToken);
        // }
        checkRegions().then(([region, latency]) => {
            setRegion(region);
            setLatency(latency);
        });
    }, []);

    async function checkRegions() {
        const regions = {
            'us-east-1': 'https://dynamodb.us-east-1.amazonaws.com/ping',
            'us-east-2': 'https://dynamodb.us-east-2.amazonaws.com/ping',
            'us-west-1': 'https://dynamodb.us-west-1.amazonaws.com/ping',
            'us-west-2': 'https://dynamodb.us-west-2.amazonaws.com/ping',
            'ca-central-1': 'https://dynamodb.ca-central-1.amazonaws.com/ping',
            // 'eu-west-1': 'https://dynamodb.eu-west-1.amazonaws.com/ping',
            'eu-west-2': 'https://dynamodb.eu-west-2.amazonaws.com/ping',
            // 'eu-west-3': 'https://dynamodb.eu-west-3.amazonaws.com/ping',
            // 'eu-central-1': 'https://dynamodb.eu-central-1.amazonaws.com/ping',
            // 'eu-north-1': 'https://dynamodb.eu-north-1.amazonaws.com/ping',
            // 'ap-south-1': 'https://dynamodb.ap-south-1.amazonaws.com/ping',
            // 'ap-northeast-2': 'https://dynamodb.ap-northeast-2.amazonaws.com/ping',
            // 'ap-southeast-1': 'https://dynamodb.ap-southeast-1.amazonaws.com/ping',
            'ap-southeast-2': 'https://dynamodb.ap-southeast-2.amazonaws.com/ping',
            // 'ap-northeast-1': 'https://dynamodb.ap-northeast-1.amazonaws.com/ping',
            // 'sa-east-1': 'https://dynamodb.sa-east-1.amazonaws.com/ping'
        };

        let fastestRegion = '';
        let fastestTime = Infinity;

        const regionPromises = Object.entries(regions).map(async ([region, url]) => {
            let startTime = Date.now();
            console.log(`Pinging ${region}`);

            try {
                await fetch(url, { mode: 'no-cors' }); // mode: 'no-cors' is needed to avoid CORS errors
                let endTime = Date.now();
                let timeTaken = endTime - startTime;

                console.log(`Pinging ${region} and it has a latency of ${timeTaken} ms`);

                if (timeTaken < fastestTime) {
                    fastestTime = timeTaken;
                    fastestRegion = region;
                }
            } catch (error) {
                console.error(`Error pinging ${region}: ${error}`);
            }
        });

        await Promise.all(regionPromises);
        console.log(fastestRegion)

        return [fastestRegion, fastestTime];
    }

    const createInstance = async () => {
        await fetch(apiBaseUrl + '/create-ec2-instance', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ 'email': email, 'region': region, 'token': token, 'promptId': promptId, 'latency': latency, 'bandwidth': bandwidth})
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else if (response.status === 304) {
                    alert("PLAICraft thinks you're still playing on the account with this email, probably because you just disconnected or you recently quit and are rapidly rejoining.  Our data gets messed up if you use the same email in two simultaneous sessions and it takes us some time to clean up after disconnects and quits.  Please try a different email or wait a few minutes and try again.")
                    throw new Error("Duplicate sessions with the same email")
                } else {
                    // An error happened somewhere. Try to check what kind of error it is.
                    response.json().then(data => {
                        let msg = JSON.stringify(data);
                        if (msg.includes("No free licenses available")) {
                            alert("All available Minecraft accounts are currently in use. Please try again later.");
                        } else if (msg.includes("Email and/or token are invalid.")) {
                            alert("Email and/or token are invalid.");
                        } else if (msg.includes("Token is expired or invalid.")) {
                            alert("Prompting link is expired or invalid.");
                        } else if (msg.includes("You have already played in this prompting session before.")) {
                            alert("You have already played in this prompting session before.")
                        } else if (msg.includes("Prompting token is valid, but the email does not match.")) {
                            alert("Your prompting link is invalid.")
                        } else if (msg.includes("Prompting token is not valid.")) {
                            alert("Your prompting link is invalid.")
                        } else {
                            alert("Something went wrong... please report this and describe what you were doing when this error occurred.");
                        }
                    })
                    throw new Error('Something went wrong.');
                }
            })
            .then(async (data) => {
                setInstanceId(data);
            })
    };

    const finalSubmit = async () => {
        console.log("final submit");
        // Check mic permissions one more time, if user has not enabled mic, then alert them and reset states
        navigator.mediaDevices.getUserMedia({ audio: true })
            .then(async (stream) => {
                setLoading(true);
                try {
                    await createInstance();
                    setIsSubmitted(true);
                } catch (error) {
                    console.log(error);
                    setLoading(false);
                }
            }
            )
            .catch(err => {
                alert("We need to use your microphone to play our game. Please enable it and retry.");
                setIsMicEnabled(false);
            }
            )
    }

    return (
        <>
            {isLoading ?
                <Fade in={isLoading} timeout={300}>
                    <div>
                        <LoadingScreen />
                    </div>
                </Fade>
                :
                <form noValidate autoComplete="off" onKeyPress={handleKeyPress}>
                    <Grid container direction="column" spacing={2}>
                        <Typography
                                variant="h5"
                                color="text.primary"
                                align="center"
                                style={{ fontWeight: 'bold', marginTop: '10px' }}
                            >
                                Join the prompting gameplay session!
                            </Typography>
                        <Grid item>
                            <Button
                                variant="contained"
                                isDisabled={!token}
                                color={isMicEnabled ? "success" : "error"}
                                onClick={() => enableMic()}
                                fullWidth
                            >
                                {isMicEnabled ? 
                                    <MicIcon fontSize='medium' sx={{ color: "white" }} /> : 
                                    <MicOffIcon fontSize='medium' />
                                }
                            </Button>
                            <Typography
                                variant="body2"
                                color="text.secondary"
                                align="center"
                                sx={{ visibility: isMicEnabled ? "hidden" : "visible" }}
                            >
                                Please enable your microphone by clicking the button. 
                            </Typography>
                        </Grid>
                        <Grid container item direction="row" alignItems="center" spacing={1} sx={{ display: "flex", justifyContent: "center" }}>
                            <Grid item sx={{ width: '50%' }}>
                                <SpeedTest latency={latency} />
                            </Grid>
                            <Grid item sx={{ width: '50%' }}>
                                <BandwidthTest bandwidth={bandwidth} setBandwidth={setBandwidth} />
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Typography
                                variant="body2"
                                color="text.secondary"
                                align="center"
                                sx={{ visibility: (latency > 200 || bandwidth < 60) ? "visible" : "hidden" }}
                            >
                                <b>Note:</b> your experience may be affected by poor connectivity.
                            </Typography>
                        </Grid>
                        <Grid container item direction="row" alignItems="center" spacing={0} sx={{ display: "flex", justifyContent: "center" }}>
                            <Grid item>
                                <CustomButton isDisabled={!token || !isMicEnabled} content="Submit" color="success" onClick={() => finalSubmit()} />
                            </Grid>
                            <Grid item ml={2}>
                                <CustomButton
                                    isDisabled={false}
                                    content="Clear"
                                    color="primary"
                                    onClick={clear}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
            }
        </>
    );
}