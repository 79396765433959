import React from 'react';
import './BackgroundVideo.css';  // import your CSS file

const BackgroundVideo = ({ videoSource }) => {
  return (
    <div className="BackgroundVideo">
      <video autoPlay="autoplay" loop="loop" muted className="Video">
        <source src={videoSource} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
}

export default BackgroundVideo;

