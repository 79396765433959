import * as React from 'react';
import ReactDOM from 'react-dom';
import { Grid, Card, CardContent, Typography, Box, Fade } from '@mui/material';
import { useSpring, useTransition, animated } from '@react-spring/web';
import { Helmet } from 'react-helmet';

export default function GameModeSelectionForm({ setCurrentForm, setSelectedGameMode }) {
  const [hoveredCard, setHoveredCard] = React.useState(null);

  const handleMouseEnter = (cardIndex) => {
    setHoveredCard(cardIndex);
  };

  const handleMouseLeave = () => {
    setHoveredCard(null);
  };

  const handleCardClick = (gameMode) => {
    const selectedMode = gameModes.find(
      (mode) => mode.mode === gameMode
    );
    if (selectedMode && selectedMode.enabled) {
      setSelectedGameMode(gameMode); // Set the selected game mode
      if (gameMode === 'standard') {
        setCurrentForm('email');
      } else if (gameMode === 'collaborative') {
        setCurrentForm('email');
      }
    }
  };

  // Configuration array for game modes
  const gameModes = [
    {
      index: 0,
      name: 'Standard SMP',
      mode: 'standard',
      enabled: true,
      image: `${process.env.PUBLIC_URL}/icons/wired-outline-268-avatar-man-hover-glance.gif`,
      description:
        'Regular survival multiplayer gameplay, you will play survival game with thousands of other players in the same world. Bedwars is also available. Mic required.',
    },
    {
      index: 1,
      name: 'Collaborative (CLC certificate)',
      mode: 'collaborative',
      enabled: false, // Set to false to disable this card
      image: `${process.env.PUBLIC_URL}/icons/wired-outline-981-consultation-hover-conversation.gif`,
      description:
        'You need to find a "padawan" to join the survival game with you, and you need to teach them how to play Minecraft. Mic required, and only the time you talk with your "padawan" will count as valid hours.',
      disabledText: 'Coming Soon', // Additional text to indicate it's disabled
    },
  ];

  // Component to render the expanded card using a portal
  function ExpandedCard({ cardIndex }) {
    const gameMode = gameModes[cardIndex];

    const transitions = useTransition(hoveredCard === cardIndex, {
      from: { opacity: 0, transform: 'translate(-50%, -50%) scale(0.95)' },
      enter: { opacity: 1, transform: 'translate(-50%, -50%) scale(1.2)' },
      leave: { opacity: 0, transform: 'translate(-50%, -50%) scale(0.95)' },
      config: { tension: 200, friction: 20 },
    });

    return ReactDOM.createPortal(
      transitions(
        (styles, item) =>
          item && (
            <animated.div
              style={{
                position: 'fixed',
                top: '50%',
                left: '50%',
                zIndex: 1001,
                cursor: gameMode.enabled ? 'pointer' : 'not-allowed',
                ...styles,
              }}
              onMouseLeave={handleMouseLeave}
              onClick={() => gameMode.enabled && handleCardClick(gameMode.mode)}
            >
              <Card
                sx={{
                  p: 2,
                  borderRadius: '10px',
                  width: '80vw',
                  maxWidth: '400px',
                  maxHeight: '90vh',
                  overflow: 'auto',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  // Dim the card if it's disabled
                  opacity: gameMode.enabled ? 1 : 1,
                  backgroundColor: gameMode.enabled ? 'background.paper' : 'grey.400',
                }}
              >
                <CardContent
                  sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
                >
                  <Box
                    component="img"
                    src={gameMode.image}
                    alt="Game Mode Logo"
                    sx={{
                      display: 'block',
                      margin: '0 auto',
                      maxWidth: '100%',
                      maxHeight: '200px',
                    }}
                  />
                  <Typography variant="h6" sx={{ mt: 2, textAlign: 'center' }}>
                    {gameMode.name}
                  </Typography>
                  <Typography variant="body2" sx={{ mt: 1, textAlign: 'center' }}>
                    {gameMode.description}
                  </Typography>
                  {!gameMode.enabled && (
                    <Typography
                      variant="caption"
                      sx={{ mt: 1, color: 'error.main', textAlign: 'center' }}
                    >
                      {gameMode.disabledText}
                    </Typography>
                  )}
                </CardContent>
              </Card>
            </animated.div>
          )
      ),
      document.body
    );
  }

  // Component for individual cards
  function GameModeCard({ cardIndex }) {
    const gameMode = gameModes[cardIndex];

    // Create a spring animation for each card individually
    const springStyle = useSpring({
      transform: hoveredCard === cardIndex ? 'scale(1.05)' : 'scale(1)',
      opacity: hoveredCard === cardIndex ? 1 : 0.9,
      config: { mass: 1, tension: 170, friction: 14 },
    });

    return (
      <Grid item xs={12} sm={6} sx={{ display: 'flex' }}>
        <animated.div
          style={{
            ...springStyle,
            cursor: gameMode.enabled ? 'pointer' : 'not-allowed',
            flexGrow: 1,
            display: 'flex',
          }}
          onMouseEnter={() => handleMouseEnter(cardIndex)}
          onMouseLeave={handleMouseLeave}
          onClick={() => gameMode.enabled && handleCardClick(gameMode.mode)}
        >
          <Card
            sx={{
              p: 1, // Reduced padding
              borderRadius: '10px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              alignItems: 'center',
              opacity: gameMode.enabled ? 1 : 0.5,
              backgroundColor: gameMode.enabled ? 'background.paper' : 'grey.300',
              width: '100%',
              height: '100%', // Ensure card fills available space
            }}
          >
            <Box
              component="img"
              src={gameMode.image}
              alt="Game Mode Logo"
              sx={{
                display: 'block',
                margin: '0 auto',
                maxWidth: '100%',
                maxHeight: '150px', // Limit image height
                height: 'auto',
                width: 'auto',
              }}
            />
            <CardContent
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: '100%',
                flexGrow: 1,
                paddingTop: 1, // Adjust padding
                paddingBottom: 0,
                '&:last-child': { paddingBottom: 0 },
              }}
            >
              <Typography variant="h6" sx={{ mt: 1, textAlign: 'center' }}>
                {gameMode.name}
              </Typography>
              {/* {!gameMode.enabled && (
                <Typography
                  variant="caption"
                  sx={{ mt: 1, color: 'error.main', textAlign: 'center' }}
                >
                  {gameMode.disabledText}
                </Typography>
              )} */}
            </CardContent>
          </Card>
        </animated.div>
      </Grid>
    );
  }

  return (
    <>
      <Helmet>
        <title>Select Your Game Mode</title>
        <meta
          name="description"
          content="Select a game mode to continue to free Minecraft access for AI research."
        />
      </Helmet>

      {hoveredCard !== null && (
        <Box
          sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100vw',
            height: '100vh',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 1000,
          }}
          onClick={() => setHoveredCard(null)}
        />
      )}

      {hoveredCard !== null && <ExpandedCard cardIndex={hoveredCard} />}

      <Fade in={true} timeout={500}>
        <Box
          sx={{
            my: 1, // Reduced vertical margin
            pt: 1, // Reduced padding top
            px: 2, // Reduced horizontal padding
            pb: 2, // Reduced padding bottom
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '100%',
            bgcolor: 'grey.200',
            borderRadius: '10px',
            position: 'relative',
            zIndex: 1,
          }}
        >
          <Typography variant="h5" gutterBottom sx={{ mb: 1 }}>
            Choose Your Gameplay Mode
          </Typography>
          <Grid container spacing={2} sx={{ mt: 1 }}>
            {gameModes.map((gameMode) => (
              <GameModeCard key={gameMode.index} cardIndex={gameMode.index} />
            ))}
          </Grid>
        </Box>
      </Fade>
    </>
  );
}
