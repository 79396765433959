import React, { useState } from 'react';
import { Grid, Card, CardContent, Typography, Box, Collapse, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const StyledCard = styled(Card)({
    backgroundColor: '#e7f3ff',
});

const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));

const InstructionPanel = ({ instructions, iframe }) => {
    const [open, setOpen] = useState(false);

    React.useEffect(() => {
        setOpen(true);
    }, []);

    const handleExpandClick = () => {
        setOpen(!open);
    };

    return (
        <Grid container direction="column" spacing={2}>
            <Grid item>
                <StyledCard>
                    <Box
                        display="flex"
                        justifyContent="space-between"
                        onClick={handleExpandClick}
                        sx={{ cursor: 'pointer' }}
                    >
                        <CardContent>
                            <Typography variant="h6" component="h2">
                                Instructions (Research Participant Portal)
                            </Typography>
                        </CardContent>
                        <ExpandMore expand={open} sx={{ marginRight: 2 }} disableRipple>
                            <ExpandMoreIcon />
                        </ExpandMore>
                    </Box>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                            padding={2}
                            paddingTop={0}
                        >
                            <Typography variant="body1" component="p">
                                {instructions}
                            </Typography>
                            {iframe}
                        </Box>
                    </Collapse>
                </StyledCard>
            </Grid>
        </Grid>
    );
}

export default InstructionPanel;
