import * as React from 'react';
import { styled, ThemeProvider } from '@mui/material/styles';
import { Button } from '@mui/material';
import theme from '../theme';

const StyledButton = styled(Button)(({ theme, color }) => `
  cursor: pointer;
  transition: ${theme.transitions.create(['background-color', 'transform'], {
    duration: theme.transitions.duration.standard,
  })};
  &:hover {
    background-color: ${theme.palette[color]?.main || theme.palette.success.main};
    color: white;
  }
`);

export default function CustomButton({ isDisabled, content, color, onClick, width, children }) {
  return (
    <ThemeProvider theme={theme}>
      <StyledButton 
        variant='outlined' 
        color={color} 
        disabled={isDisabled} 
        onClick={onClick}
        sx={{ width: width || "auto" }}
      >
        {children || content}
      </StyledButton>
    </ThemeProvider>
  );
}
