import React, { useEffect, useState } from "react";
import { Box, Typography, CircularProgress } from "@mui/material";
import { red, orange, blue, green } from '@mui/material/colors';
import deployment_config from '../deployment_config.json'

const BandwidthTest = ({ bandwidth, setBandwidth }) => {
    const [testInProgress, setTestInProgress] = useState(true);
    const DEPLOYMENT = deployment_config.deploymentName;

    useEffect(() => {
        const testBandwidth = async () => {
            const downloadUrl = `https://plai-speed-test-${DEPLOYMENT}.s3.us-west-2.amazonaws.com/100MB.bin`;
            const downloadSize = 100 * 1024 * 1024 * 8; // 100 MB in bits

            try {
                const speedMbps = await measureConnectionSpeed(downloadUrl, downloadSize);
                setBandwidth(parseFloat(speedMbps));
                setTestInProgress(false);
            } catch (error) {
                console.error(error);
                setTestInProgress(false);
            }
        };

        testBandwidth();
    }, []);

    const measureConnectionSpeed = async (downloadUrl, downloadSize) => {
        return new Promise(async (resolve, reject) => {
            try {
                const startTime = performance.now();
                const response = await fetch(downloadUrl, { cache: "no-store" });

                if (!response.ok) {
                    throw new Error("Failed to fetch file for bandwidth test");
                }

                const reader = response.body.getReader();
                let receivedLength = 0;

                while (true) {
                    const { done, value } = await reader.read();
                    if (done) break;
                    receivedLength += value.length;
                }

                const endTime = performance.now();
                const duration = (endTime - startTime) / 1000; // in seconds
                const speedMbps = ((receivedLength * 8) / duration / 1024 / 1024).toFixed(2);

                resolve(speedMbps);
            } catch (error) {
                reject(error);
            }
        });
    };

    let status = {};
    if (bandwidth < 60) {
        status.text = "Bad";
        status.color = red[500];
    } else if (bandwidth >= 60 && bandwidth <= 100) {
        status.text = "OK";
        status.color = orange[500];
    } else if (bandwidth > 100 && bandwidth <= 200) {
        status.text = "Good";
        status.color = blue[500];
    } else if (bandwidth > 200 && bandwidth <= 400) {
        status.text = "Great";
        status.color = blue[200];
    } else {
        status.text = "Excellent";
        status.color = green[500];
    }

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                border: "1px solid",
                borderColor: "divider",
                borderRadius: 1,
                p: 1,
                width: "100%",
                backgroundColor: "background.paper",
            }}
        >
            <Typography variant="subtitle2" color="text.secondary">
                Connection Speed
            </Typography>
            {testInProgress ? (
                <CircularProgress />
            ) : (
                <>
                    <Typography variant="h5" sx={{ color: status.color }}>
                        {status.text}
                    </Typography>
                    <Typography variant="h6">
                        {`${bandwidth} Mbps`}
                    </Typography>
                </>
            )}
            {testInProgress && (
                <Typography variant="body2" color="text.secondary">
                    Testing...
                </Typography>
            )}
        </Box>
    );
};

export default BandwidthTest;
